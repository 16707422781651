<template>
  <v-row no-gutters>
    <v-col cols="5">
      <fieldsValuesContainer
        :field="field"
        :result="{
          ...result,
          fields: {
            ...result.fields,
            n_systemId: { ...result.fields.n_systemId, type: 'select' },
          },
        }"
        v-on="$listeners"
        :valueLine="result.valueLine"
      >
      </fieldsValuesContainer>
    </v-col>
    <v-col> </v-col>
  </v-row>
</template>


<script>
import fieldsValuesContainer from "@/commonComponents/fieldsValuesContainer.vue";

export default {
  components: { fieldsValuesContainer },

  props: ["result", "field"],
};
</script>