<template>
  <fieldsValues
    :field="field"
    :fieldAttributes="this.result.fields[field]"
    :templateContent="result"
    @input="checkvalue(field, ...arguments)"
    :value="valueLine[field]"
  ></fieldsValues>
</template>
<script>
import fieldsValues from "@/commonComponents/fieldsValues.vue";
export default {
  props: ["result", "field", "valueLine"],
  components: { fieldsValues },
  methods: {
    checkvalue(field, value, text) {
      this.$emit("field-value-changed", field, value, text);
    },
  },
};
</script>